import { LegacyFiltersProps, LegacyFilters } from "@shopify/polaris";
import { useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { StylingModelsQueryVariables } from '../graphql/StylingModelsQuery'

type Variables = StylingModelsQueryVariables

const reducer = (prev: Variables, next: Variables): Variables => {
  return { ...prev, ...next }
}

export const useStylingModelsFilters = (
  itemPerPage: number
) => {
  const { t } = useTranslation()
  const [variables, setVariables] = useReducer(reducer, {})
  const [queryValue, setQueryValue] = useState<string>()

  useEffect(() => {
    setVariables(
      {
        ...variables,
        first: itemPerPage,
        after: null,
        before: null,
        last: null,
      }
    )
  }, [])

  const setNextPage = useCallback(
    (after: string) =>
      setVariables(
        {
          ...variables,
          before: null,
          after: after,
          first: itemPerPage,
          last: null,
        }
      ),
    [itemPerPage]
  )
  const setPreviousPage = useCallback(
    (before: string) =>
      setVariables(
        {
          ...variables,
          before: before,
          after: null,
          first: null,
          last: itemPerPage,
        }
      ),
    [itemPerPage]
  )

  useDebounce(
    () => {
      // skip initial
      if (queryValue === undefined) return
      // 遅延させてリクエストする
      setVariables(
        {
          ...variables,
          name: queryValue,
          first: itemPerPage,
          after: null,
          before: null,
          last: null,
        }
      )
    },
    400,
    [queryValue, itemPerPage]
  )

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(''),
    [setQueryValue]
  )

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove()
  }, [handleQueryValueRemove])

  const filters: LegacyFiltersProps['filters'] = []
  const appliedFilters: LegacyFiltersProps['appliedFilters'] = []

  return {
    queryValue,
    setQueryValue,
    filters,
    appliedFilters,
    handleQueryValueRemove,
    handleFiltersClearAll,
    variables,
    setNextPage,
    setPreviousPage
  }
}
